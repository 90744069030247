/*.accordion-button:not(.collapsed) {*/
/*    color: #00ffff;*/
/*    background-color: #29008a;*/
/*    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);*/
/*    padding: 0;*/
/*}*/
/*.accordion-button {*/
/*    !*color: #00ffff;*!*/
/*    background-color: #ffffff;*/
/*    !*box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);*!*/
/*    padding: 6px;*/
/*}*/
/*.accordion-body {*/
/*    !*color: #00ffff;*!*/
/*    background-color: #ffffff;*/
/*    !*box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);*!*/
/*    padding: 6px;*/
/*}*/
/*.accordion-header {*/
/*    !*color: #00ffff;*!*/
/*    background-color: #ffffff;*/
/*    !*box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);*!*/
/*    !*padding: 6px;*!*/
/*}*/
/*.accordion-button:not(.collapsed) {*/
/*    !*color: #00ffff;*!*/
/*    background-color: #ffffff;*/
/*    !*box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);*!*/
/*}*/
