.options-dialog-container > * {
    margin-bottom: 16px;
}

.options-dialog-container button {
    margin-right: 16px;
}

.options-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.options-dialog-container {
    position: relative;
    max-width: 400px;
    background: #333;
    border-radius: 6px;
    padding: 24px;
    text-align: center;
    color: #ddd;
}
