:root {
    scroll-behavior: initial;
}

.container {
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
}

.header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #2B2D42;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content {
    padding: 10px;
}

.App-link {
    color: #61dafb;
}

body {
    background-color: #1d1d1d;
    color: #f0f0f0;
}

a {
    color: #f0f0f0;
}

a:hover {
    color: #bfbfbf;
}

button {
    background-color: #555;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    margin: 1px;
}

button:hover {
    background-color: #555;
}

button:active {
    background-color: #666;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

textarea {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 16px;
}

textarea:focus {
    outline: none;
    background-color: #555;
}

textarea:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

textarea:disabled::placeholder {
    color: #ccc;
}

.separator {
    height: 30px;
    width: 1px;
    background-color: #ccc;
    margin: 0 10px;
}

.spacer {
    flex-grow: 1;
}

.modal-content {
    background-color: #555;
}