.user-prompt-node {
    min-height: 50px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: #313131;
    color: white;
    overflow: auto;
    max-width: 300px;
}

.response-node {
    min-height: 50px;
    max-width: 300px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: #818181;
    color: white;
    overflow: auto;
}

.react-flow .react-flow__handle {
    width: 28px;
    height: 12px;
    border-radius: 3px;
    background-color: #5437a1;
}

.react-flow .react-flow__handle-top {
    top: -10px;
}

.react-flow .react-flow__handle-bottom {
    bottom: -10px;
}
